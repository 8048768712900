import { colorPalette, weightPalette } from "gx-npm-common-styles";
import { tabsHeight } from "../../lib/header-styles";

const colorIron = colorPalette.neutrals.iron.hex;
const colorLightBerry = colorPalette.interactions.lightBerry.hex;
const colorWhite = colorPalette.basic.white.hex;
const defaultCta = colorPalette.interactions.defaultCta.hex;

const weightAmtBold = weightPalette.bold.amount;
const weightVarBold = weightPalette.bold.variation;

const tabMenuComponentStyles = {
  root: {
    "& button.workspace-header-class-MuiTab-root": {
      color: colorIron,
      flexBasis: "initial",
      flexGrow: "initial",
      fontSize: "15px",
      fontVariationSettings: weightVarBold,
      fontWeight: weightAmtBold,
      letterSpacing: "0.75px",
      lineHeight: "26px",
      minWidth: "auto",
      padding: "11px 34px 11px 34px",
      "&.Mui-selected": {
        "& span:first-child": {
          color: defaultCta,
        },
      },
      "&:hover": {
        background: colorLightBerry,
        borderRadius: "8px 8px 0 0",
      },
    },
    "& header": {
      background: colorWhite,
    },
    "&.scrolling": {
      "& .workspace-header-class-MuiTabs-root": {
        height: 0,
      },
    },
    "& .workspace-header-class-MuiTabs-root": {
      height: `${tabsHeight}px`,
      minHeight: "initial",
      transition: "all 0.5s ease-in-out",
    },
  },

  tabClass: {
    fontWeight: weightPalette.semiBold.amount,
    fontVariationSettings: weightPalette.semiBold.variation,
  },
  indicator: {
    "&&": {
      backgroundColor: defaultCta,
      height: "4px",
    },
  },
};

export { tabMenuComponentStyles };
