// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HSxi_LFIlHsFvyykhjTs{padding-right:81px}.HSxi_LFIlHsFvyykhjTs .Sfsf_yw_C1dujwDn0Q5l{padding-bottom:56px}.HSxi_LFIlHsFvyykhjTs .rq3kAMssohjnYHZftWnC{display:flex;align-items:center;gap:15px;padding-bottom:12px}.HSxi_LFIlHsFvyykhjTs .pzClvP2nzADHS7iZQODA{color:var(--color-neutrals-quartz);width:400px}.HSxi_LFIlHsFvyykhjTs .EavsnEm5dELM8CvQ1O8Y{width:400px;margin-bottom:56px}.HSxi_LFIlHsFvyykhjTs .MUe8Zhd1_g8K_ntNFZLx{background-color:var(--color-white);height:1px;opacity:.2;margin-bottom:12px}.HSxi_LFIlHsFvyykhjTs .BHVr7GR0rUzoGSNN07gG{margin-bottom:16px}.HSxi_LFIlHsFvyykhjTs ._9NJ35V1mnEP8wgLs8FuY .n5ooVImJXJPa71wkTHSQ{width:320px}`, "",{"version":3,"sources":["webpack://./../gx-npm-ui/src/ribbon/researchSlideIn/components/market-guide-body.styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CACA,4CACE,mBAAA,CAEF,4CACE,YAAA,CACA,kBAAA,CACA,QAAA,CACA,mBAAA,CAGF,4CACE,kCAAA,CACA,WAAA,CAGF,4CACE,WAAA,CACA,kBAAA,CAEF,4CACE,mCAAA,CACA,UAAA,CACA,UAAA,CACA,kBAAA,CAEF,4CACE,kBAAA,CAGA,mEACE,WAAA","sourcesContent":[".contentBodyMidSectionLeft {\n  padding-right: 81px;\n  & .gx-gartner-mg-desc-text {\n    padding-bottom: 56px;\n  }\n  .contentBodyMidSectionTitleText {\n    display: flex;\n    align-items: center;\n    gap: 15px;\n    padding-bottom: 12px;\n  }\n\n  .contentBodyMidSectionDescText {\n    color: var(--color-neutrals-quartz);\n    width: 400px;\n  }\n\n  .contentBodyMidSectionDescTextGCOM3523 {\n    width: 400px;\n    margin-bottom: 56px;\n  }\n  .contentLearnMoreLine {\n    background-color: var(--color-white);\n    height: 1px;\n    opacity: 0.2;\n    margin-bottom: 12px;\n  }\n  .contentBodyMidSectionLearnMore {\n    margin-bottom: 16px;\n  }\n  .contentLink {\n    .contentBodyMidSectionLinkText {\n      width: 320px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contentBodyMidSectionLeft": `HSxi_LFIlHsFvyykhjTs`,
	"gx-gartner-mg-desc-text": `Sfsf_yw_C1dujwDn0Q5l`,
	"contentBodyMidSectionTitleText": `rq3kAMssohjnYHZftWnC`,
	"contentBodyMidSectionDescText": `pzClvP2nzADHS7iZQODA`,
	"contentBodyMidSectionDescTextGCOM3523": `EavsnEm5dELM8CvQ1O8Y`,
	"contentLearnMoreLine": `MUe8Zhd1_g8K_ntNFZLx`,
	"contentBodyMidSectionLearnMore": `BHVr7GR0rUzoGSNN07gG`,
	"contentLink": `_9NJ35V1mnEP8wgLs8FuY`,
	"contentBodyMidSectionLinkText": `n5ooVImJXJPa71wkTHSQ`
};
export default ___CSS_LOADER_EXPORT___;
